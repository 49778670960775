import React, { useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"

import "../styles/pages/About.scss"

import KeyIcon1 from "../assets/images/icons/about-icon-1.svg"
import KeyIcon2 from "../assets/images/icons/about-icon-2.svg"
import KeyIcon3 from "../assets/images/icons/about-icon-3.svg"
import ClientsIcon1 from "../assets/images/icons/about-icon-4.svg"
import ClientsIcon2 from "../assets/images/icons/about-icon-5.svg"
import ClientsIcon3 from "../assets/images/icons/about-icon-6.svg"
import ClientsIcon4 from "../assets/images/icons/about-icon-7.svg"
import ClientsIcon5 from "../assets/images/icons/about-icon-8.svg"
import Hero from "../assets/images/about-person-image.png"

import Layout from "../components/Layout.jsx"
import Seo from "../components/Seo"

export default function About() {
  const intl = useIntl()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: "about-hero-title" })} />
      <main className="about">
        <section className="about__hero">
          <div className="about__container">
            <div className="about__hero-grid">
              <div className="about__hero-box">
                <span className="about__hero-text-box">
                  <h2 className="about__hero-title">
                    {intl.formatMessage({ id: "about-hero-title" })}
                  </h2>
                  <h3 className="about__hero-subtitle">
                    {intl.formatMessage({ id: "about-hero-subtitle" })}
                  </h3>
                  <p className="about__hero-paragraph">
                    {intl.formatMessage({ id: "about-hero-paragraph" })}
                  </p>
                </span>
              </div>
              <div className="about__hero-box">
                <img src={Hero} alt="Girl" className="about__hero-image" />
              </div>
            </div>
          </div>
        </section>
        <section className="about__keys">
          <div className="about__container">
            <div className="about__keys-grid">
              <article className="about__keys-item">
                <img
                  src={KeyIcon1}
                  alt="About key"
                  className="about__keys-image"
                />
                <p className="about__keys-text">
                  <span className="about__keys-text about__keys-text--bold">
                    {intl.formatMessage({ id: "about-keys-name-one" })}
                  </span>
                  {intl.formatMessage({ id: "about-keys-text-one" })}
                </p>
              </article>
              <article className="about__keys-item">
                <img
                  src={KeyIcon2}
                  alt="About key"
                  className="about__keys-image"
                />
                <p className="about__keys-text">
                  <span className="about__keys-text about__keys-text--bold">
                    {intl.formatMessage({ id: "about-keys-name-two" })}
                  </span>
                  {intl.formatMessage({ id: "about-keys-text-two" })}
                </p>
              </article>
              <article className="about__keys-item">
                <img
                  src={KeyIcon3}
                  alt="About key"
                  className="about__keys-image"
                />
                <p className="about__keys-text">
                  <span className="about__keys-text about__keys-text--bold">
                    {intl.formatMessage({ id: "about-keys-name-three" })}
                  </span>
                  {intl.formatMessage({ id: "about-keys-text-three" })}
                </p>
              </article>
            </div>
          </div>
        </section>
        <section className="about__clients">
          <div className="about__container">
            <h2 className="about__clients-title">
              {intl.formatMessage({ id: "about-clients-title" })}
              <span className="about__clients-title about__clients-title--bold">
                {intl.formatMessage({ id: "about-clients-title2" })}{" "}
              </span>
              {intl.formatMessage({ id: "about-clients-title3" })}
            </h2>
            <div className="about__clients-data">
              <article className="about__clients-item">
                <img
                  src={ClientsIcon1}
                  alt="Clients"
                  className="about__clients-image"
                />
                <p className="about__clients-paragraph">
                  {intl.formatMessage({ id: "about-clients-key-one" })}
                </p>
              </article>
              <article className="about__clients-item">
                <img
                  src={ClientsIcon2}
                  alt="Clients"
                  className="about__clients-image"
                />
                <p className="about__clients-paragraph">
                  {intl.formatMessage({ id: "about-clients-key-two" })}
                </p>
              </article>
              <article className="about__clients-item">
                <img
                  src={ClientsIcon3}
                  alt="Clients"
                  className="about__clients-image"
                />
                <p className="about__clients-paragraph">
                  {intl.formatMessage({ id: "about-clients-key-three" })}
                </p>
              </article>
              <article className="about__clients-item">
                <img
                  src={ClientsIcon4}
                  alt="Clients"
                  className="about__clients-image"
                />
                <p className="about__clients-paragraph">
                  {intl.formatMessage({ id: "about-clients-key-four" })}
                </p>
              </article>
            </div>
          </div>
        </section>
        <section className="about__more">
          <div className="about__container">
            <div className="about__more-grid">
              <div className="about__more-box">
                <h2 className="about__more-title">
                  {intl.formatMessage({ id: "about-benefits-title" })}
                </h2>
              </div>
              <div className="about__more-box">
                <ul className="about__more-list">
                  <li className="about__more-item">
                    {intl.formatMessage({ id: "about-benefits-key-one" })}
                  </li>
                  <li className="about__more-item">
                    {intl.formatMessage({ id: "about-benefits-key-two" })}
                  </li>
                  <li className="about__more-item">
                    {intl.formatMessage({ id: "about-benefits-key-three" })}
                  </li>
                  <li className="about__more-item">
                    {intl.formatMessage({ id: "about-benefits-key-four" })}
                  </li>
                  <li className="about__more-item">
                    {intl.formatMessage({ id: "about-benefits-key-five" })}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}
